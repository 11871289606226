import { useCallback } from 'react';
import type { FieldProps, FormikProps } from 'formik';

import type { ObjectId } from 'shared/types';

import type { SelectProps } from './ui';
import Select, { idSeparatorsRegex } from './ui';

export type SelectFieldProps<V> = FieldProps<V> &
  Omit<SelectProps<V>, 'onChange'> & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (value: V, form: FormikProps<any>) => void;
  };

export const SelectFieldBase = <V,>({
  field,
  form,
  onChange: theOnChange,
  ...props
}: SelectFieldProps<V>) => {
  const onChange = useCallback(
    (value: V) => {
      form.setFieldValue(field.name, value);
      theOnChange?.(value, form);
    },
    [field.name, form, theOnChange],
  );

  // @ts-expect-error mode requirement is not inferred properly
  return <Select.Base<V> value={field.value} onChange={onChange} {...props} />;
};

const SelectField = (props: SelectFieldProps<string>) => (
  <SelectFieldBase {...props} />
);

SelectField.Base = SelectFieldBase;

SelectField.defaultFilterOptions = Select.defaultFilterOptions;

SelectField.valueLabelFilterOptions = Select.valueLabelFilterOptions;

SelectField.selectAllSymbol = Select.selectAllSymbol;

SelectField.infer = <V,>() =>
  SelectFieldBase as React.ComponentType<SelectFieldProps<V>>;

// some basic SelectField variants
SelectField.ByObjectId = SelectField.infer<ObjectId>();

SelectField.idSeparatorsRegex = idSeparatorsRegex;

export default SelectField;
