import type { Bank } from 'shared/types';
import type { ClientDocumentType, ClientType } from 'entities/client';
import type { Contract } from 'entities/workplace';
import type {
  ContractType,
  PayComissionPeriod,
  PaymentMethod,
} from 'entities/contractor';
import type { TransactionType } from 'entities/transaction';
import type { ReceiptStatus } from 'entities/receipt';
import { colors } from 'shared/theme/colors';

export const PAYMENT_METHODS: Record<PaymentMethod, string> = {
  TALKBANK_API: 'ТКБ API',
  TALKBANK_REGISTRY: 'ТКБ реестры MGT',
  ALFA_RS_MGT_REGISTRY: 'Альфа р/с MGT',
  ALFA_NA_MGT_API: 'Альфа н/с MGT API',
  ALFA_NA_MGRU_API: 'Альфа н/с MGRU API',
  ALFA_NA_MGT_REGISTRY: 'Альфа н/с MGT',
  ALFA_NA_MGRU_REGISTRY: 'Альфа н/с MGRU',
  ALFA_PC_MGRU_WITH_FNS_API: 'Альфа р/с MGRU FNS_API',
  ALFA_NA_MGT_WITH_FNS_API: 'Альфа н/с MGT FNS_API',
  ALFA_PC_MGT_WITH_FNS_API: 'Альфа р/с MGT FNS_API',
  ALFA_PC_MGT_API: 'Альфа р/с MGT API',
  ALFA_PC_MGRU_API: 'Альфа р/с ГПХ MGRU_API',
  RAIFFAISEN_RS: 'Райф р/с MGRU',
  TOCHKA_BANK_MGT_NA_API: 'Точка Банк н/с MGT API',
};

export const DOC_GROUPS: Record<
  Exclude<DocGroup, 'additional_passport_pages'>,
  string
> = {
  passport: 'Паспорт',
  drive: 'Водительское удостоверение',
  medical: 'Медицинская книжка',
  covidvax: 'COVID-19',
  // labor: 'Трудовая книжка',
  // temporary_residence_permit: 'РВП',
  // vhz: 'ВНЖ'
};

export type DocGroup =
  | 'passport'
  | 'drive'
  | 'medical'
  | 'covidvax'
  | 'additional_passport_pages';

export type DocType =
  | 'passport_main'
  | 'passport_main2'
  | 'passport_selfie'
  | 'passport_registration'
  | 'passport_registration2'
  | 'legalization_main'
  | 'legalization_2'
  | 'translation'
  | 'med1'
  | 'med2'
  | 'med3'
  | 'med4'
  | 'med5'
  | 'med6'
  | 'med7'
  | 'med8'
  | 'med9'
  | 'drive_front'
  | 'drive_back'
  | 'covidvax1'
  | 'covidvax2'
  | 'covidvax3'
  | 'covidvax4'
  | 'additional_page1'
  | 'additional_page2'
  | 'additional_page3';

export const DOC_GROUPS_FULL: {
  docgroup: DocGroup;
  name: string;
  items: {
    doctype: DocType;
    name: string;
  }[];
}[] = [
  {
    docgroup: 'passport',
    name: 'Паспорт',
    items: [
      { doctype: 'passport_main', name: 'Разворот с фотографией' },
      { doctype: 'passport_selfie', name: 'Ваше фото с паспортом' },
      { doctype: 'passport_registration', name: 'Разворот с регистрацией' },
      { doctype: 'passport_main2', name: 'Обратный разворот ID' },
      { doctype: 'passport_registration2', name: 'Обратный разворот УОП' },
      { doctype: 'legalization_main', name: 'Основной разворот ВНЖ/РПВ/МК' },
      { doctype: 'legalization_2', name: 'Обратный разворот МК' },
      { doctype: 'translation', name: 'Перевод' },
    ],
  },

  {
    docgroup: 'medical',
    name: 'Медицинская книжка',
    items: [
      { doctype: 'med1', name: 'Основной разворот медкнижки' },
      { doctype: 'med2', name: 'Отметки о профилактических прививках' },
      {
        doctype: 'med3',
        name: 'Заключение врача о допуске к работе по результатам медицинского обследования',
      },
      { doctype: 'med4', name: 'Результаты обследования на туберкулёз' },
      {
        doctype: 'med5',
        name: 'Результаты исследования на носительство возбудителей кишечных инфекционных заболеваний',
      },
      {
        doctype: 'med6',
        name: 'Результаты лабораторных исследований и осмотра дерматовенеролога',
      },
      { doctype: 'med7', name: 'Результаты исследования на гельминтозы' },
      {
        doctype: 'med8',
        name: 'Результаты исследования на носительство возбудителей дифтерии',
      },
      {
        doctype: 'med9',
        name: 'Профессиональная гигиеническая подготовка и аттестация',
      },
    ],
  },

  {
    docgroup: 'drive',
    name: 'Водительское удостоверение',
    items: [
      { doctype: 'drive_front', name: 'Лицевая сторона' },
      { doctype: 'drive_back', name: 'Обратная сторона' },
    ],
  },

  {
    docgroup: 'covidvax',
    name: 'COVID-19',
    items: [
      { doctype: 'covidvax1', name: 'Страница 1' },
      { doctype: 'covidvax2', name: 'Страница 2' },
      { doctype: 'covidvax3', name: 'Страница 3' },
      { doctype: 'covidvax4', name: 'Страница 4' },
    ],
  },
  {
    docgroup: 'additional_passport_pages',
    name: 'Дополнительные страницы паспорта',
    items: [
      { doctype: 'additional_page1', name: 'Первая дополнительная страница' },
      { doctype: 'additional_page2', name: 'Вторая дополнительная страница' },
      { doctype: 'additional_page3', name: 'Третья дополнительная страница' },
    ],
  },
];

export const DOC_GROUPS_PAGES = DOC_GROUPS_FULL.reduce(
  (acc, group) => ({
    ...acc,
    [group.docgroup]: group.items,
  }),
  {} as Record<DocGroup, { doctype: DocType; name: string }[]>,
);

export const DOC_TYPES = DOC_GROUPS_FULL.reduce(
  (acc, group) => ({
    ...acc,
    ...group.items.reduce(
      (acc2, item) => ({ ...acc2, [item.doctype]: item.name }),
      {} as Record<DocType, string>,
    ),
  }),
  {} as Record<DocType, string>,
);

export const DOC_STATUSES = {
  pending: 'В ожидании',
  confirmed: 'Подтвержден',
  refused: 'Отклонён',
  null: 'Не загружен',
} as const;

export type DocStatus = keyof typeof DOC_STATUSES;

export const DOC_STATUSES_TAGS: Record<
  DocStatus,
  {
    title: string;
    color?: string;
  }
> = {
  pending: {
    title: DOC_STATUSES.pending,
    color: colors.orange,
  },
  confirmed: {
    title: DOC_STATUSES.confirmed,
  },
  refused: {
    title: DOC_STATUSES.refused,
    color: colors.red,
  },
  null: {
    title: DOC_STATUSES.null,
    color: colors.gray,
  },
};

export const MEDICAL_BOOK_STATUS = [
  { value: 'pending', caption: 'На модерации' },
  { value: 'refused', caption: 'Отклонено' },
  { value: 'confirmed', caption: 'Подтверждено' },
] as const;

export const TABLE_PAGINATION = {
  position: ['bottomLeft'],
  pageSize: 50,
} as const;

export const RECEIPT_STATUSES: {
  [status in ReceiptStatus]: {
    title: string;
    color?: string;
  };
} = {
  need_act: {
    title: 'Акт не подписан',
    color: undefined,
  },
  need_receipt: {
    title: 'Чек не прикреплён',
    color: undefined,
  },
  not_needed: {
    title: 'Не требуется',
    color: colors.green,
  },
  offline: {
    title: 'Ожидает синхронизации ИФНС',
    color: undefined,
  },
  moderation: {
    title: 'На проверке',
    color: colors.orange,
  },
  correct: {
    title: 'Принят',
    color: colors.green,
  },
  incorrect: {
    title: 'Отклонён',
    color: colors.red,
  },
  void: {
    title: 'Аннулирован ИФНС',
    color: colors.red,
  },
  bank_receipt: {
    title: 'Будет выписан банком',
    color: colors.gray,
  },
  bank_processed: {
    title: 'Выписан банком',
    color: colors.green,
  },
} as const;

export const RECEIPT_STATUSES_LIST = {} as { [key in ReceiptStatus]: string };
(Object.keys(RECEIPT_STATUSES) as (keyof typeof RECEIPT_STATUSES)[]).forEach(
  (key) => {
    RECEIPT_STATUSES_LIST[key] = RECEIPT_STATUSES[key]?.title;
  },
);

export const GENDER = {
  male: 'Мужской',
  female: 'Женский',
} as const;

export const EMPLOYMENT_STATUSES = {
  none: 'Не подтверждён',
  self_employed: 'Подтверждён',
} as const;

export const REG_SOURCES = [
  'web',
  'android',
  'ios',
  'internal',
  'import',
  'crm',
  'hrd',
] as const;

export const MOBILE_TEAM_TYPES = {
  courier: 'Курьер',
  universal: 'Универсал (РТ3/Грузчик)',
} as const;

export const CONTRACTS: Record<Contract, string> = {
  mygigru: 'mygigru',
  mygigtech: 'mygigtech',
};

export const DOCUMENT_TYPE = [
  { value: 'certificate', caption: 'Сертификат о вакцинации' },
  { value: 'reference', caption: 'Справка о медотводе' },
  { value: 'analysis', caption: 'Анализ на антитела' },
] as const;

export const COORDINATES = {
  Moscow: [37.622093, 55.753994],
} as const;

export const DATE_FORMAT = {
  TIME: 'HH:mm',
  DATE: 'DD.MM.YY',
  DATE_FULL_YEAR: 'DD.MM.YYYY',
  DAY_MONTH: 'DD.MM',
  DATE_TIME: 'DD.MM.YY HH:mm',
  DATE_BACKEND: 'YYYY-MM-DD',
  DAY_OF_WEEK_DATE: 'ddd / D.MM.YYYY',
} as const;

export const DISTANCE_LIST = {
  '0,5000': 'до 5 км.',
  '5000,10000': '5 - 10 км.',
  '10000,20000': '10 - 20 км.',
  '20000,10000000': 'более 20 км.',
} as const;

export const CITIES = [
  { id: 1, name: 'Москва', lat: 55.753215, lon: 37.622504, zoom: 11 },
  { id: 2, name: 'Санкт-Петербург', lat: 59.938951, lon: 30.315635, zoom: 13 },
  { id: 3, name: 'Воронеж', lat: 51.660781, lon: 39.200269, zoom: 13 },
  { id: 4, name: 'Волгоград', lat: 48.707067, lon: 44.516948, zoom: 13 },
  { id: 5, name: 'Екатеринбург', lat: 56.838011, lon: 60.597465, zoom: 13 },
  { id: 6, name: 'Казань', lat: 55.796289, lon: 49.108795, zoom: 13 },
  { id: 7, name: 'Краснодар', lat: 45.03547, lon: 38.975313, zoom: 13 },
  { id: 8, name: 'Красноярск', lat: 56.010563, lon: 92.852572, zoom: 13 },
  { id: 9, name: 'Нижний Новгород', lat: 56.326797, lon: 44.006516, zoom: 13 },
  { id: 10, name: 'Новосибирск', lat: 55.030199, lon: 82.92043, zoom: 13 },
  { id: 11, name: 'Омск', lat: 54.989342, lon: 73.368212, zoom: 13 },
  { id: 12, name: 'Пермь', lat: 58.01045, lon: 56.229434, zoom: 13 },
  { id: 13, name: 'Ростов-на-Дону', lat: 47.222078, lon: 39.720349, zoom: 13 },
  { id: 14, name: 'Самара', lat: 53.195538, lon: 50.101783, zoom: 13 },
  { id: 15, name: 'Челябинск', lat: 55.159897, lon: 61.402554, zoom: 13 },
  { id: 16, name: 'Уфа', lat: 54.735147, lon: 55.958727, zoom: 13 },
] as const;

export const CONSTANTS = {
  WEEKDAYS: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
  MONTHS: [
    ['январь', 'января'],
    ['февраль', 'февраля'],
    ['март', 'марта'],
    ['апрель', 'апреля'],
    ['май', 'мая'],
    ['июнь', 'июня'],
    ['июль', 'июля'],
    ['август', 'августа'],
    ['сентябрь', 'сентября'],
    ['октябрь', 'октября'],
    ['ноябрь', 'ноября'],
    ['декабрь', 'декабря'],
  ],
  SMARTPHONE_TYPE: [
    { caption: 'Андроид', value: 'android' },
    { caption: 'IOS', value: 'ios' },
    { caption: 'Нет', value: 'none' },
  ],

  // Регулярное выражение для валидации номера телефона
  PHONE_REGEX: /^7(\d{10})$/,

  ORDER_OPERATION_TYPES: {
    INCREASE_SALARY_20_PERCENT: 'increase_salary_20_percent',
  },

  EMAIL_REGEXP:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

  PASSWORD_REGEXP: /^.{10,}$/,
} as const;

export const EXTERNAL_APPLICANT = {
  crm_statuses: [
    {
      value: 'new',
      caption: 'Новый',
    },
    {
      value: 'in_work',
      caption: 'В работе',
    },
    {
      value: 'closed',
      caption: 'Закрыт',
    },
  ],
} as const;

const { REACT_APP_FRONTEND_URL } = import.meta.env;
export const FRONTEND_URL = REACT_APP_FRONTEND_URL?.replace('main', 'app');

export const WHATS_APP_TEMPLATES = [
  {
    id: 1,
    type: 'invitedForInterview',
    buttonText: 'Приглашён на собеседование',
    text:
      'Ждём вас на собеседование (дата и время) по адресу (адрес). Для регистрации на платформе необходимо выполнить 2 простых действия:\n' +
      ' 1. Загрузить фото паспорта (Более подробно во 2 разделе инструкции https://mygig.zendesk.com/hc/ru/articles/360007585157-Как-работает-My-Gig-) \n' +
      '2. Подтвердить статус самозанятого (Более подробно в 5 разделе инструкции https://mygig.zendesk.com/hc/ru/articles/360007585157-Как-работает-My-Gig-) \n' +
      'Ответы на часто задаваемые вопросы, а также подробную информацию о том, как пользоваться сервисом вы найдете тут https://mygig.zendesk.com/hc/ru/categories/360001326357-Исполнителю; \n' +
      'Видео-инструкция - https://www.youtube.com/channel/UCXEwPdQKReq4dYL-SOF_4Rw/videos \n' +
      'При возникновении любых вопросов можете обратиться в нашу техническую поддержку: \n' +
      '• По телефону: +7 (495) 215-53-58\n' +
      ' • В телеграмме: https://t.me/MyGigBot\n' +
      ' • Вконтакте: https://vk.com/public195322973',
    toReplace: [
      ['(дата и время)', 'date'],
      ['(адрес)', 'address'],
    ],
  },
  {
    id: 2,
    type: 'failedToGetThrough',
    buttonText: 'Не удалось дозвониться',
    text:
      'Это MyGig.ru - работа и подработка на карте рядом с оплатой сразу.%0a' +
      'Мы не смогли до Вас дозвониться по поводу запланированной смены. Пожалуйста, напишите мне в ответ!%0a' +
      'Или ожидайте повторный звонок в ближайшее время. Спасибо!',
    toReplace: [],
  },
  {
    id: 3,
    type: 'withoutTemplate',
    buttonText: 'Без шаблона',
    text: '',
    toReplace: [],
  },
] as const;

export const PARTNER_MED_STATUSES = {
  sent: 'направлен на оформление',
  issued: 'оформил у партнёра',
} as const;

export const CALL_TYPES = {
  incoming: 'входящий',
  outgoing: 'исходящий',
} as const;

export const SCHEDULE_TIME = {
  MIN: 8,
  MAX: 20,
} as const;

export const PLANNING_BLANKS_STATUSES = {
  init: 'Инициализирован',
  generate: 'Генерация',
  ready: 'Готов к отправке',
  send: 'Отправлен',
  fail: 'Провален',
} as const;

export const PLANNING_BLANKS_WAY_GENERATED = {
  true: 'Автоформирование',
  false: 'В ручную',
} as const;

export type JobHistoryInitiator =
  | 'worker'
  | 'operator'
  | 'timer'
  | '1c'
  | 'import'
  | 'forest'
  | 'employer'
  | 'supervisor'
  | 'zvonobot'
  | 'integration';

export const JOB_HISTORY_INITIATORS: Record<JobHistoryInitiator, string> = {
  worker: 'Гигер',
  operator: 'Оператор',
  timer: 'Таймер',
  '1c': '1С',
  import: 'Импорт',
  forest: 'Forest',
  employer: 'Заказчик',
  supervisor: 'Супервизор',
  zvonobot: 'Звонобот',
  integration: 'Интеграции',
};

export const CLIENT_DOCUMENT_TYPES: Record<ClientDocumentType, string> = {
  civil_law_agreement: 'ГПХ',
  supplementary_agreement: 'Доп. соглашение',
  offer: 'Оферта',
  other: 'Другое',
};

export const HOLD_RULE_TITLES = [
  'ЛМК',
  'ВЕЛО',
  'Недостача',
  'Ущерб(бой)',
  'Карта ТАЙМБУК',
  'Обучение',
  'Переплата',
];

export const BANK_NAMES: Record<Bank, string> = {
  mts: 'МТС',
  talkbank: 'ТолкБанк',
  raiffeisen: 'Райффайзен',
};

export const TRANSACTION_TYPES_ACTUAL: Record<
  Exclude<TransactionType, 'none'>,
  string
> = {
  prepay: 'Аванс',
  monthly_salary: 'Зарплата',
  penalty: 'Штраф',
  bonus: 'Бонус',
  gift: 'Подарок',
  hold: 'Удержание',
  surcharge: 'Доплата',
};

export const TRANSACTION_TYPES: Record<TransactionType, string> = {
  ...TRANSACTION_TYPES_ACTUAL,
  none: '—',
};

export const CLIENT_TYPES: Record<ClientType, string> = {
  saas: 'SaaS',
  enterprise: 'Enterprise',
  agency: 'Агентский',
};

export const PAY_COMISSION_PERIODS: Record<PayComissionPeriod, string> = {
  endOfWork: 'Сразу',
  everyWeek: 'Раз в неделю',
  onceMonth: 'Раз в месяц',
};

export const DATE_OF_PAYMENT = {
  endOfWork: 'В конце смены',
  everyWeek: 'Каждую неделю',
  everyTwoWeeks: 'Каждые две недели',
  onceMonth: 'Раз в месяц',
} as const;

export const CONTRACT_TYPES: Record<ContractType, string> = {
  saas: 'Платформа',
  services: 'Платформа +',
  processing: 'Платформа PRO',
};

export const URL_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;

// TODO: завести как переменную окружения когда-нибудь
const RECEIPT_DOMAIN = '.*';
export const RECEIPT_URL_REGEX = new RegExp(
  `^https://${RECEIPT_DOMAIN}/api/v1/receipt/(?<inn>\\d{12})/(?<receiptId>[\\da-zA-Z]{10})/print$`,
);
export const RECEIPT_URL_REFERRENCE = `https://${RECEIPT_DOMAIN}/api/v1/receipt/{ИНН}/{ID чека}/print`;

export const OBJECT_ID_REGEX = /^[a-f\d]{24}$/i;
export const OBJECT_ID_REGEX_PART = /[a-f\d]{24}/i;

export const DEFAULT_SEPARATORS = [',', ' ', '\r\n', '\n'];

export const UUID_REGEX =
  /^[0-9A-F]{8}\b-[0-9A-F]{4}\b-[0-9A-F]{4}\b-[0-9A-F]{4}\b-[0-9A-F]{12}$/i;

export const REGIONS: Record<string, string> = {
  Адыгея: 'Республика Адыгея',
  Алтай: 'Республика Алтай',
  Алтайский: 'Алтайский край',
  Амурская: 'Амурслая область',
  Архангельская: 'Архангельская обл.',
  Астраханская: 'Астраханская обл.',
  Башкортостан: 'Республика Башкортостан',
  Белгородская: 'Белгородская обл.',
  Брянская: 'Брянская обл.',
  Бурятия: 'Республика Бурятия',
  Владимирская: 'Владимирская обл.',
  Волгоградская: 'Волгоградская обл.',
  Вологодская: 'Вологодская обл.',
  Воронежская: 'Воронежская обл.',
  Дагестан: 'Республика Дагестан',
  'Донецкая Народная': 'Донецкая Народная Республика',
  Еврейская: 'ЕАО',
  Забайкальский: 'Забайкальский край',
  Ивановская: 'Ивановская обл.',
  Ингушетия: 'Республика Ингушения',
  Иркутская: 'Иркутская обл.',
  'Кабардино-Балкарская': 'Кабардино-Балкарская Республика',
  Калининградская: 'Калининградская обл.',
  Калмыкия: 'Республика Калмыкия',
  Калужская: 'Калужская обл.',
  Камчатский: 'Камчатский край',
  'Карачаево-Черкесская': 'Карачаево-Черкесская Республика',
  Карелия: 'Республика Карелия',
  'Кемеровская область - Кузбасс': 'Кемеровская область - Кузбасс',
  Кировская: 'Кировская обл.',
  Коми: 'Республика Коми',
  Костромская: 'Костромская обл.',
  Краснодарский: 'Краснодарский край',
  Красноярский: 'Красноярский край',
  Крым: 'Республика Крым',
  Курганская: 'Курганская обл.',
  Курская: 'Курская обл.',
  Ленинградская: 'Ленинградская обл.',
  Липецкая: 'Липецкая обл.',
  'Луганская Народная': 'Луганская Народная Республика',
  Львовская: 'Львовская обл.',
  Магаданская: 'Магаданская обл.',
  'Марий Эл': 'Республика Марий Эл',
  Мордовия: 'Республика Мордовия',
  Москва: 'Москва',
  Московская: 'Московская обл.',
  Мурманская: 'Мурманская обл.',
  Нижегородская: 'Нижегородская обл.',
  Новгородская: 'Новгородская обл.',
  Новосибирская: 'Новосибирская обл.',
  Омская: 'Омская обл.',
  Оренбургская: 'Оренбургская обл.',
  Орловская: 'Орловская обл.',
  Павлодарская: 'Павлодарская обл.',
  Пензенская: 'Пензенская обл.',
  Пермский: 'Пермский край',
  Приморский: 'Приморский край',
  Псковская: 'Псковская обл.',
  Ростовская: 'Ростовская обл.',
  Рязанская: 'Рязанская обл.',
  Самарская: 'Самарская обл.',
  'Санкт-Петербург': 'Санкт-Петербург',
  Саратовская: 'Саратовская обл.',
  'Саха /Якутия/': 'Республика Саха (Якутия)',
  Сахалинская: 'Сахалинская обл.',
  Свердловская: 'Свердловская обл.',
  Севастополь: 'Севастополь',
  'Северная Осетия - Алания': 'Республика Северная Осетия - Алания',
  Смоленская: 'Смоленская обл.',
  Ставропольский: 'Ставропольский край',
  Тамбовская: 'Тамбовская обл.',
  Татарстан: 'Республика Татарстан',
  Тверская: 'Тверская обл.',
  Томская: 'Томская обл.',
  Тульская: 'Тульская обл.',
  Тыва: 'Республика Тыва',
  Тюменская: 'Тюменская обл.',
  Удмуртская: 'Удмуртская Республика',
  Ульяновская: 'Ульяновская обл.',
  Хабаровский: 'Хабаровский край',
  Хакасия: 'Республика Хакасия',
  'Ханты-Мансийский Автономный округ - Югра':
    'Ханты-Мансийский Автономный округ - Югра',
  Харьковская: 'Харьковская обл.',
  Херсонская: 'Херсонская обл.',
  Челябинская: 'Челябинская обл.',
  Чеченская: 'Чеченская Республика',
  'Чувашская Республика': 'Чувашская Республика',
  'Ямало-Ненецкий': 'ЯНАО',
  Ярославская: 'Ярославская обл.',
};

export const WORKPLACE_SOURCES = ['mygig', 'LT_PROD'];

// он у нас только в одном экземпляре, так что это и дев, и продовый
export const HEATMAP_TOKEN = 'q8aLXf9kN7neQ8ql9lUJ';
