import { CalendarOutlined } from '@ant-design/icons';
import { DatePicker, type DatePickerProps } from 'antd';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import type { FieldProps } from 'formik';

interface DateFieldProps
  extends FieldProps<string>,
    Omit<DatePickerProps, 'value' | 'onChange' | 'form'> {
  format?: string;
  dataFormat?: string;
  suffix?: React.ReactNode;
}

const DateField = ({
  field,
  form,
  format = 'DD.MM.YYYY',
  dataFormat = 'YYYY-MM-DD',
  disabled,
  allowClear,
  suffix,
  suffixIcon = <CalendarOutlined />,
  ...props
}: DateFieldProps) => {
  const onChange = (value: Dayjs | null) => {
    form.setFieldValue(
      field.name,
      value ? value.format(dataFormat) : undefined,
    );
  };

  return (
    <>
      <DatePicker
        {...props}
        disabled={disabled}
        allowClear={allowClear}
        suffixIcon={
          suffix === null ? null : (
            <>
              <span style={{ pointerEvents: 'auto' }}>{suffix}</span>
              {!suffix || (!disabled && allowClear) ? suffixIcon : null}
            </>
          )
        }
        format={props.showTime ? format : [format, format.replace(/W/gi, '')]}
        value={field.value ? dayjs(field.value, dataFormat) : null}
        onChange={onChange}
      />
    </>
  );
};

export default DateField;
