import {
  type ComponentType,
  type ReactNode,
  createElement,
  lazy,
  useMemo,
} from 'react';
import { Redirect } from 'react-router-dom';

import Login from 'components/Login';
import ResetPassword from 'components/ResetPassword';
import type { OperatorRole } from 'entities/operator';
import operatorModel from 'entities/operator';
import { DOC_GROUPS } from 'shared/constants';

type Route = {
  sidebar?: string;
  sidebarFooter?: boolean;
  path: string;
} & (
  | {
      render: () => ReactNode;
      component?: never;
    }
  | {
      component: ComponentType;
      render?: never;
    }
) &
  (
    | {
        whitelist?: OperatorRole[];
        blacklist?: never;
      }
    | {
        blacklist?: OperatorRole[];
        whitelist?: never;
      }
  );

const routes: Route[] = [
  {
    path: '/',
    render: () => createElement(Redirect, { to: '/orders' }, null),
  },

  // ---
  {
    sidebar: 'Проекты',
    path: '/clients',
    component: lazy(() => import('pages/clients')),
  },
  {
    path: '/clients/new',
    component: lazy(() => import('pages/client-create')),
    blacklist: ['operator'],
  },
  {
    path: '/clients/:clientId',
    component: lazy(() => import('pages/client-page')),
  },

  // ---
  {
    sidebar: 'Группы проектов',
    path: '/groups',
    component: lazy(() => import('pages/groups')),
    whitelist: ['admin', 'content'],
  },

  // ---
  {
    path: '/workplaces',
    sidebar: 'Рабочие точки',
    component: lazy(() => import('pages/workplaces')),
  },
  {
    path: '/workplaces/new',
    component: lazy(() => import('pages/workplace-create')),
  },
  {
    path: '/workplaces/:id',
    component: lazy(() => import('pages/workplace-page')),
  },

  // ---
  {
    sidebar: 'Контрагенты',
    path: '/contractors',
    component: lazy(() => import('pages/contractors')),
  },
  {
    path: '/contractors/new',
    component: lazy(() => import('pages/contractor-create')),
    blacklist: ['operator'],
  },
  {
    path: '/contractors/:id',
    component: lazy(() => import('pages/contractor-edit')),
  },

  // ---
  {
    sidebar: 'Заказчики',
    path: '/employers',
    component: lazy(() => import('pages/employers')),
    whitelist: ['admin', 'content', 'coordinator'],
  },
  {
    path: '/employers/new',
    component: lazy(() => import('pages/employer-create')),
    whitelist: ['content', 'admin'],
  },
  {
    path: '/employers/:id',
    component: lazy(() => import('pages/employer-page')),
    whitelist: ['admin', 'content', 'coordinator'],
  },

  // ---
  {
    sidebar: 'Шаблоны',
    path: '/templates',
    component: lazy(() => import('pages/templates')),
    whitelist: ['admin', 'content'],
  },

  // ---
  {
    sidebar: 'Заказы',
    path: '/orders',
    component: lazy(() => import('pages/orders')),
  },
  {
    path: '/orders/new',
    component: lazy(() => import('pages/order-create')),
    blacklist: ['operator'],
  },
  {
    path: '/orders/:id',
    component: lazy(() => import('pages/order-page')),
  },
  {
    path: '/orders/:id/edit',
    component: lazy(() => import('pages/order-edit')),
  },

  // ---
  {
    sidebar: 'Координация',
    path: '/coordination',
    component: lazy(() => import('pages/coordination')),
  },

  // ---
  {
    sidebar: 'Тепловая карта',
    path: '/heatmap',
    component: lazy(() => import('pages/heatmap')),
    whitelist: ['admin', 'heatmap_operator'],
  },

  // ---
  {
    sidebar: 'Смены',
    path: '/jobs',
    component: lazy(() => import('pages/jobs')),
  },
  {
    path: '/jobs/:id/',
    component: lazy(() => import('pages/job-page')),
  },

  // ---
  {
    sidebar: 'Гигеры',
    path: '/workers',
    component: lazy(() => import('pages/workers')),
  },
  {
    path: '/workers/new',
    component: lazy(() => import('pages/worker-create')),
    blacklist: ['operator'],
  },
  {
    path: '/workers/:workerId',
    component: lazy(() => import('pages/worker-page')),
  },

  // ---
  {
    sidebar: 'Собеседования',
    path: '/interviews',
    component: lazy(() => import('pages/interviews')),
  },

  // ---
  {
    sidebar: 'Подбор',
    path: '/outsourcing',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    component: lazy(() => import('pages/outsourcing-selection')),
  },

  // ---
  {
    sidebar: 'Внешние отклики',
    path: '/external-applicants',
    component: lazy(() => import('pages/external-applicants')),
  },

  // ---
  {
    sidebar: 'Платежи',
    path: '/payments',
    component: lazy(() => import('pages/payments')),
  },

  // ---
  {
    sidebar: 'Транзакции',
    path: '/transactions',
    component: lazy(() => import('pages/transactions')),
  },
  {
    path: '/transactions/new',
    component: lazy(() => import('pages/transaction-create')),
    blacklist: ['operator'],
  },
  {
    path: '/transactions/:id',
    component: lazy(() => import('pages/transaction-page')),
  },

  // ---
  {
    sidebar: 'Акции',
    path: '/referrals',
    component: lazy(() => import('pages/referrals')),
  },

  // ---
  {
    sidebar: 'Документы',
    path: '/documents',
    component: lazy(() => import('pages/documents')),
    blacklist: ['operator'],
  },
  {
    path: `/documents/:userId/:documentGroup(${Object.keys(DOC_GROUPS).join(
      '|',
    )})`,
    component: lazy(() => import('pages/document-page')),
    blacklist: ['operator'],
  },

  // ---
  {
    sidebar: 'Поддержка',
    path: '/support',
    component: lazy(() => import('pages/support-list')),
  },
  {
    path: '/support/:id',
    component: lazy(() => import('pages/support-page')),
  },

  // ---
  {
    sidebar: 'Выгрузка отчётов',
    path: '/reports',
    component: lazy(() => import('pages/reports')),
  },

  // ---
  {
    sidebar: 'Регулярные отчёты',
    path: '/regular-reports',
    component: lazy(() => import('pages/regular-reports')),
  },

  // ---
  {
    sidebar: 'Импорт CSV',
    path: '/csvimport',
    component: lazy(() => import('pages/csv-import')),
  },

  // ---
  {
    sidebar: 'Чеки',
    path: '/receipts',
    component: lazy(() => import('pages/receipts')),
  },

  // ---
  {
    sidebar: 'Операторы',
    path: '/operators',
    component: lazy(() => import('pages/operators')),
    whitelist: ['admin', 'content'],
  },
  {
    path: '/operators/new',
    component: lazy(() => import('pages/operator-create')),
    whitelist: ['admin'],
  },
  {
    path: '/operators/:id',
    component: lazy(() => import('pages/operator-page')),
    whitelist: ['admin', 'content'],
  },

  // ---
  {
    sidebar: 'Настройки',
    path: '/settings',
    component: lazy(() => import('pages/settings')),
    sidebarFooter: true,
    whitelist: ['admin', 'financial_manager'],
  },

  // ---
  {
    path: '*',
    render: () => <h1>404 Страница не найдена</h1>,
  },
];

export const unauthorizedRoutes: Route[] = [
  {
    path: '/reset-password',
    component: ResetPassword,
  },
  {
    path: '*',
    component: Login,
  },
];

const disallowRoute = (route: Route): Route => ({
  ...route,
  component: undefined,
  sidebar: undefined,
  render: () => <h1>Доступ запрещён</h1>,
});

export const useAllowedRoutes = () => {
  const operatorQ = operatorModel.queries.useCurrent();
  const roles = operatorQ.data?.roles;
  return useMemo(() => {
    return routes.map((route) => {
      if (roles?.includes('super_admin')) {
        return route;
      }
      if (route.whitelist?.length) {
        return roles?.some((role) => route.whitelist?.includes(role)) ?
            route
          : disallowRoute(route);
      }
      if (route.blacklist?.length) {
        return !roles?.some((role) => route.blacklist?.includes(role)) ?
            route
          : disallowRoute(route);
      }
      return route;
    });
  }, [roles]);
};

export default routes;
