import { CalendarOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import type { FieldProps } from 'formik';
import { useCallback, useMemo, useState } from 'react';

interface DateRangeFieldProps
  extends FieldProps<[string, string] | undefined>,
    Omit<RangePickerProps, 'value' | 'onChange' | 'form'> {
  format?: string;
  dataFormat?: string;
  suffix?: React.ReactNode;
  limit?: number;
}

export declare type EventValue<DateType> = DateType | null;
export declare type RangeValue<DateType> =
  | [EventValue<DateType>, EventValue<DateType>]
  | null;

const considerLimits = (
  value: RangeValue<Dayjs>,
  limit?: number,
  range?: 'start' | 'end',
): RangeValue<Dayjs> => {
  if (limit && value && value[0] && value[1]) {
    const diff = value[1].diff(value[0], 'days');
    if (Math.abs(diff) > limit - 1) {
      if (range === 'end') {
        const value0 = value[1].subtract(limit - 1, 'days');
        return [value0, value[1]];
      }
      const value1 = value[0].add(limit - 1, 'days');
      return [value[0], value1];
    }
  }
  return value;
};

const DateRangeField = ({
  field,
  form,
  format = 'DD.MM.YYYY',
  dataFormat = 'YYYY-MM-DD',
  presets: thePresets,
  disabled,
  allowClear,
  suffix,
  suffixIcon = <CalendarOutlined />,
  limit,
  onOpenChange,
  onCalendarChange,
  ...props
}: DateRangeFieldProps) => {
  const [dates, setDates] = useState<RangeValue<Dayjs>>(null);

  const value: RangePickerProps['value'] = useMemo(
    () =>
      field.value ?
        [
          field.value[0] ? dayjs(field.value?.[0]) : null,
          field.value[1] ? dayjs(field.value?.[1]) : null,
        ]
      : null,
    [field.value],
  );

  const presets: RangePickerProps['presets'] = useMemo(() => {
    if (thePresets) return thePresets;
    const presets: RangePickerProps['presets'] = [];

    const now = dayjs();
    presets.push({
      label: 'Сегодня',
      value: [now, now],
    });
    if (limit && limit >= 7) {
      presets.push({
        label: 'Эта неделя',
        value: [now.startOf('week'), now.endOf('week')],
      });
    }
    if (!limit || limit >= now.daysInMonth()) {
      presets.push({
        label: 'Этот месяц',
        value: [now.startOf('month'), now.endOf('month')],
      });
    }
    return presets;
  }, [limit, thePresets]);

  const disabledDate = useCallback(
    (current: Dayjs) => {
      if (!limit) return true;
      if (!dates || !dates.filter(Boolean).length) {
        return false;
      }
      const tooLate = !!dates[0] && current.diff(dates[0], 'days') > limit - 1;
      const tooEarly = !!dates[1] && dates[1].diff(current, 'days') > limit - 1;

      return tooEarly || tooLate;
    },
    [dates, limit],
  );

  return (
    <DatePicker.RangePicker
      {...props}
      presets={presets}
      disabled={disabled}
      allowClear={allowClear}
      suffixIcon={
        suffix === null ? null : (
          <>
            <span style={{ pointerEvents: 'auto' }}>{suffix}</span>
            {!suffix || (!disabled && allowClear) ? suffixIcon : null}
          </>
        )
      }
      format={[format, format.replace(/\W/gi, '')]}
      disabledDate={limit ? disabledDate : undefined}
      onCalendarChange={(v, r, info) => {
        onCalendarChange?.(v, r, info);
        const value = considerLimits(v, limit, info.range);
        setDates(value);
        let newValue;
        if (value && value.length) {
          newValue = [
            value[0]?.format(dataFormat),
            value[1]?.format(dataFormat),
          ];
        }
        form.setFieldValue(field.name, newValue);
      }}
      onOpenChange={(open) => {
        onOpenChange?.(open);
        if (!open) return;
        setDates(null);
      }}
      value={value}
    />
  );
};

export default DateRangeField;
