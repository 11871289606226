import { DownOutlined } from '@ant-design/icons';
import type { SelectProps as AntSelectProps } from 'antd';
import { Select as AntSelect } from 'antd';
import { useCallback, useMemo, useState } from 'react';

import { DEFAULT_SEPARATORS } from 'shared/constants';
import type { ObjectId } from 'shared/types';

import type { OptionLabelType, OptionType } from '../types';

import css from './styles.module.scss';

type FlattenValue<V> =
  V extends string[] ? Exclude<V, undefined>
  : V extends string ? Exclude<V, undefined>[]
  : never;

export type SelectProps<V> = Omit<
  AntSelectProps<V, OptionType<V>>,
  'options' | 'autoClearSearchValue'
> & {
  /** Record версия слабо типизируется с Opaque типами, для них рекомендуется использовать обычные опции */
  options?:
    | (V extends string ? Record<Exclude<V, undefined>, OptionLabelType>
      : never)
    | (V extends string[] ?
        Record<Exclude<V[number], undefined>, OptionLabelType>
      : never)
    | OptionType<V extends (infer U)[] ? U : V>[]
    | FlattenValue<V>
    | Record<string, FlattenValue<V>>;
  grouped?: boolean;
  suffix?: React.ReactNode;
  /** для options типа Record */
  enabledOptions?: Exclude<
    V extends string ? V
    : V extends string[] ? V[number]
    : never,
    undefined
  >[];
  /** для options типа Record */
  disabledOptions?: Exclude<
    V extends string ? V
    : V extends string[] ? V[number]
    : never,
    undefined
  >[];
  selectAllFiltered?: boolean;
  persistSearch?: boolean;
  idSeparators?: string[];
  separateById?: boolean;
  tagsParser?: (v?: string) => string;
} & (V extends unknown[] ? { mode: 'multiple' | 'tags' } : object);

export const recordToOptionTypes = <V extends string>(
  record: Record<V, OptionLabelType>,
  map?: (entry: [V, OptionLabelType]) => Partial<OptionType<V>>,
): OptionType<V>[] =>
  (Object.entries(record) as [V, OptionLabelType][]).map(([value, label]) => ({
    value,
    label,
    ...(map ? map([value, label]) : {}),
  }));

const defaultFilterOptions: SelectProps<unknown>['filterOption'] = (
  input,
  option,
) => {
  const terms = input.toLowerCase().split(/[\s,]+/);
  return terms.every((term) =>
    typeof option?.label === 'string' ?
      option?.label?.toLowerCase().includes(term)
    : false,
  );
};

const valueLabelFilterOptions: SelectProps<unknown>['filterOption'] = (
  input,
  option,
) => {
  const terms = input.toLowerCase().split(/[\s,]+/);
  return (
    terms.every((term) =>
      typeof option?.label === 'string' ?
        option?.label?.toLowerCase().includes(term)
      : false,
    ) || option?.value === input
  );
};

const isOptionTypesArr = (
  arr: unknown[] | OptionType<unknown>[],
): arr is OptionType<unknown>[] => typeof arr[0] === 'object';

/** can't use symbols as values so we just pretend it is one */
const selectAllSymbol = "new Symbol('select all')" as const;

export const idSeparatorsRegex = (idSeparators: string[]) =>
  new RegExp(`[${idSeparators.join('')}]`);

export const SelectBase = <V,>({
  value,
  options: theOptions,
  grouped,
  filterOption = defaultFilterOptions,
  disabled,
  suffix,
  suffixIcon = <DownOutlined style={{ pointerEvents: 'none' }} />,
  allowClear,
  defaultValue,
  disabledOptions,
  enabledOptions,
  onChange: theOnChange,
  onSelect: theOnSelect,
  onBlur,
  children,
  showSearch,
  selectAllFiltered,
  maxTagCount = 5,
  persistSearch,
  onSearch,
  idSeparators = DEFAULT_SEPARATORS,
  separateById,
  tagsParser,
  ...props
}: SelectProps<V>) => {
  const [search, setSearch] = useState<string>();

  const optionDisabled = useCallback(
    (option: Exclude<V, undefined>) =>
      !!(
        (disabledOptions as V[])?.includes(option) ||
        (enabledOptions && !(enabledOptions as V[]).includes(option))
      ),
    [disabledOptions, enabledOptions],
  );

  const options: (
    | OptionType<V>
    | { label: string; options: OptionType<V>[] }
  )[] = useMemo(() => {
    if (!theOptions) return [];
    if (grouped) {
      return Object.entries(theOptions as Record<string, FlattenValue<V>>).map(
        ([group, values]) => ({
          label: group ?? 'Без группы',
          options: values.map(
            (value) =>
              ({
                value,
                label: value,
                key: group + value,
              }) as OptionType<V>,
          ),
        }),
      );
    }
    if (!Array.isArray(theOptions)) {
      return recordToOptionTypes(
        theOptions as Exclude<
          typeof theOptions,
          Record<string, FlattenValue<V>>
        >,
        ([value, label]) => ({
          value,
          label,
          disabled: optionDisabled(value),
        }),
      );
    }
    if (isOptionTypesArr(theOptions)) return theOptions as OptionType<V>[];
    return theOptions.map((v) => ({
      value: v,
      label: v,
    })) as OptionType<V>[];
  }, [theOptions, optionDisabled, grouped]);

  const flatOptions = useMemo(
    () => options.flatMap((o) => ('options' in o ? o.options : o)),
    [options],
  );

  const optionsWithSelectAll = useMemo(
    () =>
      [
        {
          className: css.controlsOption,
          value: selectAllSymbol as V,
          label: `Выбрать все${search ? ' подходящие' : ''}...`,
        },
        ...options,
      ] as OptionType<V>[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options, !!search],
  );

  const showSelectAll =
    selectAllFiltered && showSearch !== false && flatOptions.length > 0;

  const onChange = useCallback<Exclude<typeof theOnChange, undefined>>(
    (val, option) => {
      if (val === selectAllSymbol) return;
      if (Array.isArray(val) && val.includes(selectAllSymbol)) return;
      theOnChange?.(val, option);
    },
    [theOnChange],
  );

  const onSelect: typeof theOnSelect = (val, option) => {
    if (search && !persistSearch) setSearch(undefined);
    theOnSelect?.(val, option);
    if (val === selectAllSymbol) {
      const prev =
        value ?
          Array.isArray(value) ?
            value
          : [value]
        : [];
      const filtered =
        search && typeof filterOption === 'function' ?
          flatOptions.filter((o) => filterOption(search, o))
        : flatOptions;
      const combined = [...prev, ...filtered.map((o) => o.value)];
      const unique = Array.from(new Set(combined)) as V;
      onChange(unique, option);
    }
  };

  const handleSearch: typeof onSearch = useCallback(
    (s: string) => {
      let val = s;
      if (
        props.mode === 'multiple' &&
        separateById &&
        idSeparators?.length &&
        flatOptions.some((o) => val.includes(o.value as string))
      ) {
        const parts = val.trim().split(idSeparatorsRegex(idSeparators));
        const ids: V[] = [];
        const rest: string[] = [];
        parts.forEach((part) => {
          if (flatOptions.some((o) => o.value === part)) {
            ids.push(part as V);
          } else if (part) {
            rest.push(part);
          }
        });
        const fieldValue = (value as V[]) ?? [];
        const nextValue = [
          ...fieldValue,
          ...ids.filter(
            (id) => !(fieldValue?.length && fieldValue?.includes(id)),
          ),
        ];
        onChange(
          nextValue as V,
          flatOptions.filter((o) => nextValue.includes(o.value))[0],
        );
        val = rest.join(idSeparators[0]);
      } else if (props.mode === 'tags' && tagsParser) {
        val = tagsParser(val);
      }
      setSearch(val);
      onSearch?.(val);
    },
    [
      onChange,
      value,
      idSeparators,
      onSearch,
      flatOptions,
      props.mode,
      separateById,
      tagsParser,
    ],
  );

  return (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        width: '100%',
      }}
    >
      <AntSelect
        showSearch
        defaultValue={defaultValue}
        searchValue={search}
        {...props}
        disabled={disabled}
        allowClear={allowClear}
        options={
          showSelectAll ? optionsWithSelectAll : (options as OptionType<V>[])
        }
        suffixIcon={
          <>
            <span style={{ fontSize: 14 }}>{suffix}</span>
            {!suffix || (!disabled && allowClear) ? suffixIcon : null}
          </>
        }
        value={value}
        onSelect={onSelect}
        onSearch={handleSearch}
        onBlur={(e) => {
          onBlur?.(e);
          setSearch(undefined);
        }}
        filterOption={
          typeof filterOption === 'boolean' ? filterOption : (
            (s, o) => {
              if (o?.value === selectAllSymbol) return true;
              return filterOption(s, o as OptionType<V>);
            }
          )
        }
        onChange={onChange}
        maxTagCount={maxTagCount}
      />
      {children}
    </span>
  );
};

const Select = (props: SelectProps<string>) => <SelectBase {...props} />;

Select.Base = SelectBase;

Select.defaultFilterOptions = defaultFilterOptions;

Select.valueLabelFilterOptions = valueLabelFilterOptions;

Select.selectAllSymbol = selectAllSymbol;

Select.idSeparatorsRegex = idSeparatorsRegex;

Select.infer = <V,>() => SelectBase as React.ComponentType<SelectProps<V>>;

// some basic SelectField variants
Select.ByObjectId = Select.infer<ObjectId>();

export default Select;
