export const phoneFormatter = (s?: string) => {
  if (!s || s?.length < 1) return '';
  let fm = '+7';
  let raw = s;
  if (['7', '8'].includes(s[0])) {
    if (s.length === 1) return fm;
  } else {
    raw = `7${raw}`;
  }
  fm += ' (';
  fm += raw.slice(1, 4);
  if (raw.length <= 4) return fm;
  fm += ') ';
  fm += raw.slice(4, 7);
  if (raw.length <= 7) return fm;
  fm += '-';
  fm += raw.slice(7, 9);
  if (raw.length <= 9) return fm;
  fm += '-';
  fm += raw.slice(9);
  return fm;
};

export const pass = <T,>(v: T) => v;

export const parseNumber = (v?: string): `${number}` => {
  const num = v?.replace(/\D/g, '') as `${number}`;
  if (!num?.length) return '' as `${number}`;
  if (num.startsWith('7') || num.startsWith('8')) {
    return num;
  }
  return `7${num}` as `${number}`;
};
