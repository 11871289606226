import { Button, Typography, notification } from 'antd';
// eslint-disable-next-line import/no-unresolved
import { registerSW } from 'virtual:pwa-register';

const pollingInterval = 60 * 1000;

const updateSW = registerSW({
  onRegisteredSW(swUrl, r) {
    r &&
      setInterval(async () => {
        if (r.installing || !navigator) return;

        if ('connection' in navigator && !navigator.onLine) return;

        const resp = await fetch(swUrl, {
          cache: 'no-store',
          headers: {
            cache: 'no-store',
            'cache-control': 'no-cache',
          },
        });

        if (resp?.status === 200) await r.update();
      }, pollingInterval);
  },
  onNeedRefresh() {
    const key = 'refresh-notification';
    notification.info({
      message: 'Доступна новая версия CRM',
      description: (
        <>
          <Typography.Paragraph>
            Перезагрузите страницу для обновления до новой версии сайта.
          </Typography.Paragraph>
          <Typography.Paragraph>
            В случае отмены возможны ошибки при работе со старой версией.
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Typography.Text type="danger">
              Будут перезагружены все активные вкладки CRM.
            </Typography.Text>
          </Typography.Paragraph>
        </>
      ),
      duration: 0,
      key,
      btn: (
        <>
          <Button onClick={() => notification.destroy(key)}>Отмена</Button>
          <Button type="primary" onClick={() => updateSW()}>
            Перезагрузить страницу
          </Button>
        </>
      ),
    });
  },
});

export default updateSW;
