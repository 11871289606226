import { ClockCircleOutlined } from '@ant-design/icons';
import { TimePicker, type TimeRangePickerProps } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import type { FieldProps } from 'formik';
import { useMemo } from 'react';

interface TimeRangeFieldProps
  extends FieldProps<
      [`${string}:${string}`, `${string}:${string}`] | undefined
    >,
    Omit<TimeRangePickerProps, 'value' | 'onChange' | 'form'> {
  format?: string;
  dataFormat?: string;
  suffix?: React.ReactNode;
}

const TimeRangeField = ({
  field,
  form,
  format = 'HH:mm',
  dataFormat = 'HH:mm',
  disabled,
  allowClear,
  suffix,
  suffixIcon = <ClockCircleOutlined />,
  needConfirm = false,
  ...props
}: TimeRangeFieldProps) => {
  const value: RangePickerProps['value'] = useMemo(() => {
    if (!field.value?.length) return null;
    return [
      dayjs(field.value[0], dataFormat),
      dayjs(field.value[1], dataFormat),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFormat, field.value]);

  const onChange: RangePickerProps['onChange'] = (value) => {
    if (!value) {
      form.setFieldValue(field.name, undefined);
      return;
    }
    form.setFieldValue(field.name, [
      value[0]?.format(dataFormat),
      value[1]?.format(dataFormat),
    ]);
  };

  return (
    <TimePicker.RangePicker
      {...props}
      disabled={disabled}
      allowClear={allowClear}
      needConfirm={needConfirm}
      suffixIcon={
        suffix === null ? null : (
          <>
            <span style={{ pointerEvents: 'auto' }}>{suffix}</span>
            {!suffix || (!disabled && allowClear) ? suffixIcon : null}
          </>
        )
      }
      format={[format, format.replace(/\W/gi, '')]}
      value={value}
      onChange={onChange}
    />
  );
};

export default TimeRangeField;
