import type { FieldProps } from 'formik';
import type { AutoCompleteProps } from 'antd';
import { AutoComplete } from 'antd';
import { useMemo } from 'react';

import type { OptionType } from '../types';

interface AutoCompleteFieldProps<V extends string | undefined = string>
  extends FieldProps<V>,
    Omit<AutoCompleteProps<V, OptionType<V>>, 'value' | 'form' | 'options'> {
  /** return true to prevent default logic */
  onChange?: AutoCompleteProps<V, OptionType<V>>['onChange'];
  options: OptionType<V>[] | Exclude<V, undefined>[];
  parser?: (value: string) => string;
  formatter?: (value: string) => string;
  clearEmpty?: boolean;
}

const isOptionTypeArray = <V,>(
  options: OptionType<V>[] | Exclude<V, undefined>[],
): options is OptionType<V>[] => !!(options[0] as OptionType<V>)?.value;

const AutoCompleteFieldBase = <V extends string | undefined = string>({
  field,
  form,
  clearEmpty,
  onChange: theOnChange,
  options: theOptions,
  ...props
}: AutoCompleteFieldProps<V>) => {
  const options = useMemo<OptionType<V>[]>(() => {
    if (!theOptions?.length) return [] as OptionType<V>[];
    if (isOptionTypeArray(theOptions)) return theOptions;
    return theOptions.map((o) => ({ label: o, value: o }));
  }, [theOptions]);

  return (
    <AutoComplete
      {...props}
      options={options}
      value={field.value}
      onChange={(val, o) => {
        if (theOnChange?.(val, o)) return;
        if (clearEmpty && val === '') {
          form.setFieldValue(field.name, undefined);
        } else {
          form.setFieldValue(field.name, val);
        }
      }}
    />
  );
};

const AutoCompleteField = (props: AutoCompleteFieldProps) => (
  <AutoCompleteFieldBase {...props} />
);

AutoCompleteField.infer = <V extends string | undefined>() =>
  AutoCompleteFieldBase as React.ComponentType<AutoCompleteFieldProps<V>>;

export default AutoCompleteField;
