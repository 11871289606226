import { Button, Col, Row, message } from 'antd';
import cn from 'classnames';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';

import operatorModel from 'entities/operator';
import FormField, { InputField } from 'shared/ui/fields';
import Icons from 'shared/ui/icons';

import css from './index.module.scss';

interface LoginFormValues {
  email: string;
  password: string;
}

const LoginField = FormField.infer<LoginFormValues>(() => ({
  noMargin: true,
  noPadding: true,
}));

const Login = () => {
  const login = operatorModel.mutations.useLogin({
    onError: (err) => {
      const errorData = err.response?.data;
      const errorText =
        typeof errorData === 'string' ? errorData : errorData?.message;
      const text =
        errorText ?? JSON.stringify(errorText) ?? 'Сервер обновляется';
      void message.error(text);
    },
  });

  return (
    <Formik<LoginFormValues>
      initialValues={{ email: '', password: '' }}
      onSubmit={async (values) => {
        await login.mutateAsync(values);
      }}
      validate={(values) => {
        const errors = {} as Partial<LoginFormValues>;
        for (const key of ['email', 'password'] as const) {
          if (!values[key]) {
            errors[key] = ' ';
          }
        }
        return errors;
      }}
    >
      {({ isSubmitting, isValid }) => (
        <Form className={cn(css.loginForm, 'box')}>
          <div className={cn(css.loginFormHeader, 'flex flex-center mb3')}>
            {Icons.logo}
            <div className={css.loginFormTitle}>CRM</div>
          </div>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <LoginField
                name="email"
                component={InputField}
                markRequired
                placeholder="Email"
              />
            </Col>
            <Col span={24}>
              <LoginField
                name="password"
                type="password"
                component={InputField}
                markRequired
                placeholder="Пароль"
              />
              <Link to="/reset-password">Забыли пароль?</Link>
            </Col>
            <Col span={24}>
              <Button
                htmlType="submit"
                type="primary"
                loading={isSubmitting}
                disabled={!isValid}
              >
                Войти
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default Login;
