import type { CSSProperties, ComponentType, ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import type { FieldProps } from 'formik';
import type { CheckboxProps } from 'antd';
import { Checkbox } from 'antd';
import type {
  CheckboxChangeEvent,
  CheckboxGroupProps,
} from 'antd/lib/checkbox';
import type { GetProp } from 'antd/lib';

import type { OptionType } from '../types';
import { recordToOptionTypes } from '../select';

interface CheckboxFieldProps
  extends FieldProps<boolean>,
    Omit<CheckboxProps, 'checked' | 'onChange'> {
  checkboxLabel?: ReactNode;
  labelStyle?: CSSProperties;
  reverse?: boolean;
  clearFalse?: boolean;
  clearTrue?: boolean;
}

const CheckboxField = ({
  field,
  form,
  checkboxLabel: label,
  style,
  labelStyle,
  reverse = false,
  clearFalse,
  clearTrue,
  ...props
}: CheckboxFieldProps) => {
  const onChange = useCallback(
    (e: CheckboxChangeEvent) => {
      const isChecked = e.target.checked;
      if (
        (clearFalse && isChecked === reverse) ||
        (clearTrue && isChecked !== reverse)
      ) {
        form.setFieldValue(field.name, undefined);
        return;
      }
      form.setFieldValue(field.name, reverse !== isChecked);
    },
    [clearFalse, reverse, clearTrue, form, field.name],
  );

  const isChecked =
    (clearTrue && field.value === undefined) ||
    field.value === true ||
    field.value?.toString().toLowerCase() === 'true';

  return (
    <Checkbox
      style={{ width: '100%', display: 'inline-flex', ...style }}
      onChange={onChange}
      checked={reverse !== isChecked}
      {...props}
    >
      <span style={labelStyle}>{label}</span>
    </Checkbox>
  );
};

interface CheckboxFieldGroupProps<V extends string[] | undefined>
  extends FieldProps<V>,
    Omit<CheckboxGroupProps, 'options'> {
  direction?: 'vertical' | 'horizontal';
  options:
    | Exclude<V, undefined>
    | Record<Exclude<V, undefined>[number], string>
    | OptionType<Exclude<V, undefined>[number]>[];
  value?: V;
  defaultValue?: V;
  disabledValues?: Exclude<V, undefined>;
}

const CheckboxFieldGroup = <V extends string[] | undefined>({
  field,
  form,
  style,
  direction = 'vertical',
  options: theOptions,
  onChange: theOnChange,
  disabledValues,
  ...props
}: CheckboxFieldGroupProps<V>) => {
  const onChange = useCallback<GetProp<CheckboxGroupProps, 'onChange'>>(
    (value) => {
      form.setFieldValue(field.name, value);
      theOnChange?.(value);
    },
    [field.name, form, theOnChange],
  );

  const options = useMemo(() => {
    if (!Array.isArray(theOptions)) {
      return recordToOptionTypes(theOptions, ([value, label]) => ({
        value,
        label,
        disabled: disabledValues?.includes(value),
      }));
    }
    if (disabledValues) {
      return theOptions.map((option) =>
        typeof option === 'string' ?
          {
            value: option,
            label: option,
            disabled: disabledValues.includes(option),
          }
        : {
            ...option,
            disabled: disabledValues.includes(option.value) ?? option.disabled,
          },
      ) as OptionType<Exclude<V, undefined>[number]>[];
    }
    return theOptions;
  }, [disabledValues, theOptions]);

  return (
    <Checkbox.Group
      style={
        direction === 'vertical' ?
          {
            display: 'flex',
            flexDirection: 'column',
            ...style,
          }
        : style
      }
      onChange={onChange}
      value={field.value}
      options={options}
      {...props}
    />
  );
};

CheckboxFieldGroup.infer = <V extends string[] | undefined>() =>
  CheckboxFieldGroup as ComponentType<CheckboxFieldGroupProps<V>>;

CheckboxField.Group = CheckboxFieldGroup;

export default CheckboxField;
