import { Button, Popover } from 'antd';
import type { ReactNode } from 'react';

interface NoticeProps {
  notice: ReactNode;
  /** 10 / '10px' */
  indent?: number | string;
}

const Notice = ({ notice, indent = 0 }: NoticeProps) => (
  <Popover content={notice} trigger="click" placement="top">
    <Button
      style={{
        marginLeft: indent,
        borderRadius: '100%',
        width: 24,
        height: 24,
        padding: 0,
        flexShrink: 0,
      }}
    >
      ?
    </Button>
  </Popover>
);

export default Notice;
