import React from 'react';
import type { FieldProps, FormikProps } from 'formik';
import type { RadioChangeEvent, RadioGroupProps } from 'antd';
import { Radio } from 'antd';

type ValueType = string | number | boolean | undefined;
type OptionType<T extends ValueType> = {
  label: string | JSX.Element;
  value: T;
  disabled?: boolean;
};

interface RadioFieldProps<T extends ValueType = ValueType>
  extends FieldProps<T>,
    Omit<RadioGroupProps, 'value' | 'onChange'> {
  options?: OptionType<Exclude<T, undefined>>[];
  status?: 'warning';
  suffix?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: T, form: FormikProps<any>) => void;
}

const RadioFieldBase = <T extends ValueType = ValueType>({
  field,
  form,
  status,
  suffix,
  onChange: theOnChange,
  ...props
}: RadioFieldProps<T>) => {
  const onChange = (e: RadioChangeEvent) => {
    form.setFieldValue(field.name, e.target.value);
    theOnChange?.(e.target.value as T, form);
  };

  return (
    <div>
      <Radio.Group
        {...props}
        style={{
          ...(status ?
            {
              outline: `${
                status === 'warning' ? '#faad14' : '#ff4d4f'
              } 1px solid`,
              borderRadius: 2,
            }
          : {}),
        }}
        value={field.value}
        onChange={onChange}
      />
      {suffix ?
        <span style={{ marginLeft: 8 }}>{suffix}</span>
      : null}
    </div>
  );
};

const RadioField = (props: RadioFieldProps) => <RadioFieldBase {...props} />;

RadioField.infer = <T extends ValueType>() =>
  RadioFieldBase as React.ComponentType<RadioFieldProps<T>>;

RadioField.Option = Radio;

export default RadioField;
