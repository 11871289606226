import { ClockCircleOutlined } from '@ant-design/icons';
import { TimePicker, type TimePickerProps } from 'antd';
import dayjs from 'dayjs';
import type { FieldProps } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';

interface TimeFieldProps
  extends FieldProps<string>,
    Omit<TimePickerProps, 'value' | 'onChange' | 'form'> {
  format?: string;
  dataFormat?: string;
  suffix?: React.ReactNode;
  showFooter?: boolean;
}

const TimeField = ({
  field,
  form,
  format = 'HH:mm',
  dataFormat = 'HH:mm',
  disabled,
  allowClear,
  suffix,
  suffixIcon = <ClockCircleOutlined />,
  onBlur: theOnBlur,
  popupClassName,
  showFooter,
  ...props
}: TimeFieldProps) => {
  const [value, setValue] = useState(field.value);

  useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  const onBlur = useCallback<Exclude<typeof theOnBlur, undefined>>(
    (e, base) => {
      form.setFieldValue(field.name, value);
      theOnBlur?.(e, base);
    },
    [field.name, form, theOnBlur, value],
  );

  return (
    <TimePicker
      {...props}
      disabled={disabled}
      allowClear={allowClear}
      suffixIcon={
        suffix === null ? null : (
          <>
            <span style={{ pointerEvents: 'auto' }}>{suffix}</span>
            {!suffix || (!disabled && allowClear) ? suffixIcon : null}
          </>
        )
      }
      format={[format, format.replace(/\W/gi, '')]}
      value={value ? dayjs(value, dataFormat) : null}
      onSelect={(v) => setValue(v?.format(dataFormat))}
      onBlur={onBlur}
      popupClassName={cn(popupClassName, {
        'ant-picker-no-footer': !showFooter,
      })}
    />
  );
};

export default TimeField;
