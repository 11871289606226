import cn from 'classnames';
import type { FieldProps } from 'formik';
import React, { type HTMLAttributes, useMemo } from 'react';

interface DisplayFieldProps<V = string>
  extends FieldProps<V>,
    HTMLAttributes<HTMLSpanElement> {
  display?: (value: V) => React.ReactNode;
  fallback?: React.ReactNode;
}

const DisplayFieldBase = <V extends string | number | undefined = string>({
  field,
  display,
  fallback,
  className,
  ...props
}: DisplayFieldProps<V>) => {
  const toRender = useMemo(() => {
    if (!display) return field.value;
    return display(field.value);
  }, [field.value, display]);

  return (
    <span
      className={cn(
        'ant-input-affix-wrapper',
        'ant-input-affix-wrapper-disabled',
        className,
      )}
      {...props}
    >
      {toRender ?? fallback}
    </span>
  );
};

const DisplayField = (props: DisplayFieldProps) => (
  <DisplayFieldBase {...props} />
);

DisplayField.infer = <V,>() =>
  DisplayFieldBase as React.ComponentType<DisplayFieldProps<V>>;

export default DisplayField;
