import type { InputProps as AntInputProps } from 'antd';
import { Input as AntInput } from 'antd';
import React from 'react';

import { pass } from './helpers';

export interface InputBaseProps extends AntInputProps {
  /** return true to prevent default logic */
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void | boolean;
  /** onChange with parsing */
  onChangeParsed?: (value?: string) => void;
  parser?: (value: string) => string;
  formatter?: (value: string) => string;
  clearEmpty?: boolean;
}

const InputBase = ({
  parser = pass,
  formatter,
  clearEmpty,
  onChange: theOnChange,
  onChangeParsed,
  value,
  ...props
}: InputBaseProps) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (theOnChange?.(e)) return;
    const val = parser(e.target.value);
    if (clearEmpty && val === '') {
      onChangeParsed?.(undefined);
    } else {
      onChangeParsed?.(val);
    }
    if (!formatter) return;
    const caret = e.target.selectionStart ?? 0;
    const prior = e.target.value.slice(0, caret);
    const properPrior = formatter(parser(prior));
    const formatterOffset = properPrior.length - prior.length;
    const element = e.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret + formatterOffset;
      element.selectionEnd = caret + formatterOffset;
    });
  };

  return (
    <AntInput
      value={formatter ? formatter(parser(value?.toString() ?? '')) : value}
      onChange={onChange}
      {...props}
    />
  );
};

export default InputBase;
