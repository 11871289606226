import type { FormikValues } from 'formik';
import type { Context, ReactNode } from 'react';
import { createContext, useContext } from 'react';

import type { NestedKeyOf } from 'shared/types';

export interface FormContextType<Values extends FormikValues> {
  alternativeValues?: Values;
  readOnly?: boolean;
  disabledFields?: NestedKeyOf<Values>[];
  /** if present, disables all the other fields */
  enabledFields?: NestedKeyOf<Values>[];
  noPadding?: boolean;
  noMargin?: boolean;
  smallLabel?: boolean;
  requiredFields?: NestedKeyOf<Values>[];
  markRequiredFields?: NestedKeyOf<Values>[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FormContext = createContext<FormContextType<any>>({});

interface WrapperProps<Values extends FormikValues>
  extends FormContextType<Values> {
  children: ReactNode | ReactNode[];
}

export const FormContextWrapper = <Values extends FormikValues>({
  children,
  ...values
}: WrapperProps<Values>) => {
  return <FormContext.Provider value={values}>{children}</FormContext.Provider>;
};

/**
 * аналогично useFormikContext
 * достоверность данных их типу зависит от здравомыслия разработчика
 */
export const useFormContext = <Values extends FormikValues>() =>
  useContext<FormContextType<Values>>(
    FormContext as Context<FormContextType<Values>>,
  );
