import type { CSSProperties } from 'react';

interface ErrorMessageProps {
  error?: string | null;
}

const style = {
  color: 'red',
  fontSize: 10,
  // paddingTop: 5,
  position: 'absolute',
} as CSSProperties;

const ErrorMessage = ({ error = null }: ErrorMessageProps) => {
  if (!error) return <></>;

  return <div style={style}>{error}</div>;
};

export default ErrorMessage;
