import BaseDateField from './date';
import DateRangeField from './date-range';
import TimeField from './time';
import TimeRangeField from './time-range';

const DateField = BaseDateField as typeof BaseDateField & {
  Range: typeof DateRangeField;
  Time: typeof TimeField;
  TimeRange: typeof TimeRangeField;
};

DateField.Range = DateRangeField;

DateField.Time = TimeField;

DateField.TimeRange = TimeRangeField;

export default DateField;
