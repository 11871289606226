import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { App as AntApp, ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import ruRU from 'antd/lib/locale/ru_RU';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import calendar from 'dayjs/plugin/calendar';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { BrowserRouter, Route, Router, Switch } from 'react-router-dom';

import operatorModel from 'entities/operator';
import { initMainSocket } from 'features/socket';
import { LayoutContext } from 'shared/contexts';
import { queryClient } from 'shared/query';
import Preloader from 'shared/ui/preloader';

import history from './history';
import { unauthorizedRoutes, useAllowedRoutes } from './routes';
import updateSW from './service-worker';
import Sidebar from './sidebar';

import './styles/index.scss';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(isSameOrBefore);
dayjs.extend(calendar);

dayjs.updateLocale('ru', {
  weekdaysShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  weekdaysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  monthsShort: [
    'янв.',
    'фев.',
    'мар.',
    'апр.',
    'мая',
    'июн.',
    'июл.',
    'авг.',
    'сен.',
    'окт.',
    'ноя.',
    'дек.',
  ],
  week: {
    dow: 1,
    doy: 4,
  },
});

dayjs.locale('ru');
dayjs.tz.setDefault('Europe/Moscow');

void updateSW();

const Layout = () => {
  const operatorQ = operatorModel.queries.useCurrent();
  useEffect(() => {
    if (operatorQ.isSuccess) {
      initMainSocket();
    }
  }, [operatorQ.isSuccess]);

  const [layoutRef, setLayoutRef] = useState<HTMLDivElement | null>(null);
  const ref = useCallback((node: HTMLDivElement) => {
    if (node) {
      setLayoutRef(node);
    }
  }, []);

  const allowedRoutes = useAllowedRoutes();

  if (operatorQ.isLoading) {
    return <Preloader fullscreen />;
  }

  if (!operatorQ.data) {
    return (
      <Switch>
        {unauthorizedRoutes.map((route) => (
          <Route key={route.path} {...route} />
        ))}
      </Switch>
    );
  }

  return (
    <>
      <Sidebar />
      <Suspense fallback={<Preloader fullscreen />}>
        <div className="layout" ref={ref}>
          <LayoutContext.Provider value={layoutRef}>
            <Switch>
              {allowedRoutes.map((route) => (
                <Route key={route.path} {...route} exact />
              ))}
            </Switch>
          </LayoutContext.Provider>
        </div>
      </Suspense>
    </>
  );
};

const App = () => (
  <ConfigProvider
    locale={ruRU}
    theme={{
      components: {
        Checkbox: {
          colorPrimary: '#f7ce17',
          colorPrimaryText: '#000',
        },
      },
      hashed: false,
    }}
  >
    <AntApp className="app-wrapper">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Router history={history}>
            <Layout />
          </Router>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </AntApp>
  </ConfigProvider>
);

export default App;
